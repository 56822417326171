import { useMutation } from "react-query"
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react"
import { useRouter } from "next/router"
import { fetchChangeQtyProduct, fetchRemoveProduct } from "@/api/cartAPI"
import { fetchProductById } from "@/api/productsAPI"
import { getAvailableStatus, getIsAvailable } from "@/components/Available"
import { useCompares } from "@/hooks/compares"
import { useFavorites } from "@/hooks/favorites"
import { NOTIFICATION_KIND } from "@/hooks/notification/constants"
import { useWindowSize } from "@/hooks/useWindowSize"
import { setDiscount } from "@/store/reducers/cartSlice"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { trackGTM } from "@/utils/analytics"
import {
  createFullPath,
  getImagePath,
  getTranslationProperties,
  readBooleanFromStorage,
} from "@/utils/common/helpers"
import { ROUTES } from "@/utils/constants"
import {
  DEFAULT_QTY,
  getGTMPayloadProduct,
  getImages,
  getIsKit,
  getProductPath,
  initialState,
  removeZeroStart,
} from "./helpers"
import { reducer } from "./reducer"
import { ProductActionTypes, UseProductType } from "./types"
import { useAuth } from "../auth/auth"
import { useCart } from "../cart/cart"
import { useClipboardCopy } from "../clipboardCopy"
import { useNotifications } from "../notification/notification"
import { useAppDispatch } from "../redux"

const { trackProductAddToCart, trackProductRemoveFromCart } = trackGTM

export const useProduct: UseProductType = ({
  product: {
    uuid = null,
    alias = null,
    max_qty = 0,
    min_qty = 0,
    params = [],
    stores: initialStores = [],
    price = 0,
    name = null,
    description = null,
    fast_qty = 0,
    is_bestseller = false,
    is_new = false,
    allow_sample = false,
    article = null,
    code = null,
    kit = [],
    kit_parents = [],
    categories = [],
    total_qty = 0,
    images: initialImages = [],
    child,
    variations = [],
    ...productInput
  },
  options: { parentKit, isSaveOnRemove = true, onAddToCart, initQty } = {},
}) => {
  const { pathname } = useRouter()
  const { isAuth } = useAuth()
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const initialCountRef = useRef<number | null>(initQty || null)
  const isLog = useRef<boolean>(false)
  const dispatchRedux = useAppDispatch()
  const { push: pushNotification } = useNotifications()
  const { width } = useWindowSize()
  const isMoreSm =
    width !== undefined && width > getBreakpointVal(breakpoints.sm)

  const [
    {
      count,
      counter,
      unit,
      isInit,
      inCart,
      currentSpecification,
      isRemoved,
      isAnimate,
      isCountError,
      stores,
      totalQty,
      units,
    },
    dispatch,
  ] = useReducer(reducer, {
    ...initialState,
    count: initialCountRef.current,
    counter: initialCountRef.current,
    totalQty: total_qty,
  })

  const unitMeasure = productInput?.unit || null
  const isOnlyContainers = !!productInput?.containers?.only_containers
  const initialUnits = useMemo(
    () => productInput?.containers?.units || [],
    [productInput?.containers?.units],
  )

  const images = useMemo(() => getImages(initialImages), [initialImages])

  const storesAvailable = useMemo(
    () => stores.filter(({ quantity = 0 }) => quantity > 0),
    [stores],
  )

  const { isFavorites, isFetching: isFetchingFavorites } = useFavorites(
    uuid || undefined,
  )

  const {
    addProduct: addToCompares,
    removeProduct: removeFromCompares,
    products,
  } = useCompares()

  const isCompares = useMemo(
    () => products.uuids.includes(uuid || ""),
    [products.uuids, uuid],
  )

  const {
    token,
    updateToken,
    updateSpecification,
    specification,
    updateTotalCost,
    updateProductCost,
    removeSpecification,
    addProductInFetching,
    removeProductInFetching,
    showToLogin,
  } = useCart()

  const { isCopied: isCopiedPath, handleCopyClick: handleCopyPath } =
    useClipboardCopy()
  const { isCopied: isCopiedCode, handleCopyClick: handleCopyCode } =
    useClipboardCopy()
  const { isCopied: isCopiedArticle, handleCopyClick: handleCopyArticle } =
    useClipboardCopy()

  const [isAdded, setIsAdded] = useState(false)

  const [isShowShare, setIsShowShare] = useState<boolean>(false)
  const [sharePath, setSharePath] = useState<string | null>(null)

  const latestUnitRef = useRef<number | null>(unit)
  const isCalledCountChangedRef = useRef<boolean>(false)
  const latestIsInit = useRef(false)
  const latestCountInCart = useRef<number | null>(null)
  const latestCount = useRef<number | null>(null)

  const properties = useMemo(
    () => getTranslationProperties(productInput?.properties || []),
    [productInput?.properties],
  )
  const priceCalc = price * (count || 1)
  const path = getProductPath(alias || undefined)

  const isVariate = variations.length > 0

  const isAvailable = getIsAvailable(total_qty)

  const availableStatus = useMemo(
    () =>
      getAvailableStatus({
        quantity: totalQty,
        maxQty: max_qty,
        minQty: min_qty,
      }),
    [max_qty, min_qty, totalQty],
  )

  const { analogs: makedAnalogs, ids: makedAnalogsIds } = useMemo(() => {
    const analogs = [
      ...(productInput?.analogs?.fast || []),
      ...(productInput?.analogs?.other || []),
    ].filter((item) => !!item.uuid)

    return {
      analogs,
      ids: analogs.map((item) => item.uuid).filter(Boolean) as string[],
    }
  }, [productInput?.analogs?.fast, productInput?.analogs?.other])

  const [isTriggerAddToCart, setIsTriggerAddToCart] = useState(false)

  const setInCartHandle = useCallback((value) => {
    dispatch({
      type: ProductActionTypes.setInCart,
      payload: value,
    })
  }, [])

  const setIsCountErrorHandle = useCallback((value) => {
    dispatch({
      type: ProductActionTypes.setIsCountError,
      payload: value,
    })
  }, [])

  const updateDiscount = useCallback(
    (discount?: number | null) => {
      dispatchRedux(setDiscount(discount || 0))
    },
    [dispatchRedux],
  )

  const createSharedPath = () => {
    const p = createFullPath(path)
    setSharePath(p)

    return p
  }

  const copyPath = () => {
    const p = createSharedPath()
    if (!p) {
      return
    }
    handleCopyPath(p)
  }

  const copyCode = useCallback(() => {
    if (!code) {
      return
    }
    handleCopyCode(code)
  }, [handleCopyCode, code])

  const copyArticle = useCallback(() => {
    if (!article) {
      return
    }
    handleCopyArticle(article)
  }, [handleCopyArticle, article])

  const share = () => {
    createSharedPath()
    setIsShowShare(true)
  }

  const toggleCompares = useCallback(() => {
    if (uuid === null) return

    if (isCompares) {
      removeFromCompares({
        products: [uuid],
      })
    } else {
      addToCompares({
        product: uuid,
        category: categories[0],
      })
    }
  }, [addToCompares, isCompares, removeFromCompares, uuid, categories])

  const { mutate: changeQtyMutate } = useMutation(fetchChangeQtyProduct, {
    onSuccess: (response, variables) => {
      console.log("[product] changeQtyMutate ")
      if (uuid === null) {
        return
      }
      if (token === null) {
        updateToken(response.cart || null)
      }

      trackProductAddToCart({
        product: {
          ...getGTMPayloadProduct({
            product: {
              uuid,
              name,
              variations,
              alias,
              price,
            },
          }),
          quantity: response.addedQty,
        },
      })

      // если на момент добавления в корзину изменилось наличие на складах
      // обновляем в стейте
      if (
        variables.quantity > response.addedQty &&
        variables.quantity <= total_qty
      ) {
        dispatch({
          type: ProductActionTypes.setTotalQty,
          payload: {
            total: response.addedQty,
          },
        })
        setCurrentUnit(response.addedQty)
      } else {
        if (totalQty !== total_qty) {
          dispatch({
            type: ProductActionTypes.setTotalQty,
            payload: {
              total: total_qty,
            },
          })
        }
      }

      if (
        (!inCart || isRemoved) &&
        (kit.length > 0 || kit_parents.length > 0)
      ) {
        setIsAdded(true)
      }

      updateSpecification({
        uuid: uuid,
        quantity: response.addedQty !== 0 ? response.addedQty : undefined,
        isRemoved: false,
        kit: kit,
      })

      // логика для товара, который является частью комплекта
      // если добавили такой товар, но не передали его parent
      // значит в корзине товар должен добавиться как самостоятельный отдельный item
      if (variables.parent === undefined && !!parentKit) {
        updateSpecification({
          uuid: uuid,
          isAnimate: true,
        })

        const { current: countInCart } = latestCountInCart
        if (countInCart !== null) {
          dispatch({
            type: ProductActionTypes.setCount,
            payload: {
              count: countInCart,
              parentKit: parentKit,
            },
          })
          dispatch({
            type: ProductActionTypes.setCounter,
            payload: {
              counter: countInCart,
            },
          })
        }
      }

      setIsFetching(false)
      updateTotalCost(response.total_cost)
      updateProductCost(response.product_cost)
      updateDiscount(response.discount)
      removeProductInFetching(uuid || "")
    },
    onError: () => {
      setIsFetching(false)
      removeProductInFetching(uuid || "")
    },
    onMutate: () => {
      setIsFetching(true)
      addProductInFetching(uuid || "")
    },
    mutationKey: "changeQtyProduct",
  })

  const { mutate: removeProductMutate } = useMutation(fetchRemoveProduct, {
    onSuccess: (response) => {
      if (uuid === null) {
        return
      }

      trackProductRemoveFromCart({
        product: {
          ...getGTMPayloadProduct({
            product: {
              uuid,
              name,
              variations,
              alias,
              price,
            },
          }),
          quantity: currentSpecification?.quantity,
        },
      })

      dispatch({
        type: ProductActionTypes.setIsRemoved,
        payload: true,
      })
      setIsAdded(false)

      if (isSaveOnRemove) {
        updateSpecification({
          uuid: uuid,
          isRemoved: true,
          parent: parentKit,
        })
      } else {
        if (uuid !== undefined) {
          removeSpecification(uuid)
        }
      }

      setIsFetching(false)
      updateTotalCost(response.total_cost)
      updateDiscount(response.discount)
      removeProductInFetching(uuid || "")
    },
    onError: () => {
      dispatch({
        type: ProductActionTypes.setIsRemoved,
        payload: false,
      })
      dispatch({
        type: ProductActionTypes.setCount,
        payload: {
          count: unit,
          parentKit,
        },
      })
      removeProductInFetching(uuid || "")
      setIsFetching(false)
    },
    onMutate: () => {
      setIsFetching(true)
      addProductInFetching(uuid || "")
    },
    mutationKey: "removeProduct",
  })

  const addToCart = useCallback(() => {
    const { current: countInCart } = latestCountInCart
    const { current: count } = latestCount

    if (!uuid || count === null || isFetching || !totalQty) {
      return
    }

    if (isLog.current) {
      console.log("[addToCart]")
    }

    let quantityCalc = count
    if (parentKit !== undefined) {
      if (isLog.current) {
        console.log("[addToCart] parentKit ", parentKit)
      }
      if (countInCart !== null && quantityCalc > countInCart) {
        quantityCalc = count - countInCart
      }
    }

    if (isLog.current) {
      console.log(
        "[addToCart] quantityCalc ",
        quantityCalc,
        " count ",
        count,
        " countInCart ",
        countInCart,
      )
    }

    if (quantityCalc !== countInCart || count !== countInCart || isRemoved) {
      const qty = quantityCalc > 0 ? quantityCalc : count

      if (qty > totalQty) {
        dispatch({
          type: ProductActionTypes.setIsCountError,
          payload: true,
        })
        return
      }

      changeQtyMutate(
        {
          product: uuid,
          quantity: qty,
          cart: token || undefined,
        },
        onAddToCart,
      )
      let isOfferToLogIn = true
      if (!isAuth) {
        isOfferToLogIn = readBooleanFromStorage(
          sessionStorage,
          "offerToLogInCart",
        )

        if (!isOfferToLogIn) {
          sessionStorage.setItem("offerToLogInCart", "true")
          showToLogin()
        }
      }
      if (
        pathname !== ROUTES.cart &&
        isMoreSm &&
        !countInCart &&
        !getIsKit(kit) &&
        isOfferToLogIn
      ) {
        fetchProductById({
          server: false,
          uuid,
          fields: ["images"],
        })
          .then(({ images }) => {
            const [image] = images ?? []
            pushNotification({
              kind: NOTIFICATION_KIND.CART,
              image: getImagePath(image),
              replace: true,
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, [
    uuid,
    isFetching,
    totalQty,
    parentKit,
    isRemoved,
    changeQtyMutate,
    token,
    isAuth,
    pathname,
    isMoreSm,
    kit,
    showToLogin,
    pushNotification,
  ])

  const removeFromCart = useCallback(() => {
    if (!uuid || !token) {
      return
    }
    removeProductMutate({
      cart: token,
      product: uuid,
      parent: parentKit,
    })
  }, [parentKit, token, uuid, removeProductMutate])

  const setCurrentCount = useCallback(
    (value: number, withError?: boolean) => {
      if (isLog.current) {
        console.log("[setCurrentCount] value ", value)
      }
      let _withError = withError

      const { current: latestUnit } = latestUnitRef
      const { current: isCalled } = isCalledCountChangedRef
      if (!isCalled) {
        if (
          latestUnit !== null &&
          latestUnit > totalQty &&
          value === latestUnit
        ) {
          isCalledCountChangedRef.current = true
          _withError = false
        }
      }

      dispatch({
        type: ProductActionTypes.setCounter,
        payload: {
          counter: value,
        },
      })

      if (inCart) {
        if (value < 1) {
          removeFromCart()
          return
        }
      }

      dispatch({
        type: ProductActionTypes.setCount,
        payload: {
          count: value,
          isLog: isLog.current,
          witError: _withError,
          parentKit,
        },
      })
    },
    [inCart, removeFromCart, totalQty, parentKit],
  )

  const setCurrentUnit = useCallback(
    (value: number) => {
      if (isLog.current) {
        console.log("[setCurrentUnit] value ", value)
      }

      dispatch({
        type: ProductActionTypes.setUnit,
        payload: {
          unit: value,
        },
      })

      const { current: unit } = latestUnitRef
      const { current: initialCount } = initialCountRef
      const valueCount = initialCount || value
      if (valueCount === unit || unit === -1) {
        return
      }
      setCurrentCount(valueCount, true)
    },
    [setCurrentCount],
  )

  const initialCartHandle = useCallback(
    ({ qty }: { qty: number }) => {
      if (isLog.current) {
        console.log("[initialCartHandle] qty ", qty)
      }
      latestCountInCart.current = qty
      setCurrentCount(qty)
    },
    [setCurrentCount],
  )

  const initialDefaultHandle = useCallback(() => {
    if (isLog.current) {
      console.log("[initialDefaultHandle] ", units)
    }
    if (units === null) {
      return
    }
    latestCountInCart.current = null
    setCurrentUnit(+(units[0]?.value || DEFAULT_QTY))
  }, [units, setCurrentUnit])

  const initialHandle = useCallback(() => {
    // не была получена корзина
    if (currentSpecification === null) {
      return
    }

    if (isLog.current) {
      console.log("[initialHandle] currentSpecification ", currentSpecification)
    }

    const { quantity = 0, isRemoved } = currentSpecification

    // если quantity undefined - это значит добавлен образец, но не товар
    if (quantity > 0 && !isRemoved) {
      initialCartHandle({
        qty: quantity,
      })
    } else {
      initialDefaultHandle()
    }

    latestIsInit.current = true
    dispatch({
      type: ProductActionTypes.setIsInit,
      payload: true,
    })
  }, [currentSpecification, initialCartHandle, initialDefaultHandle])

  const updateCurrentCount = useCallback(
    (value: number) => {
      const { current: isInit } = latestIsInit
      if (!isInit) {
        return
      }
      if (isLog.current) {
        console.log("updateCurrentCount ext value ", value)
      }
      setCurrentCount(value, true)
    },
    [setCurrentCount],
  )

  const updateCurrentUnit = useCallback(
    (value: number) => {
      const { current: isInit } = latestIsInit
      if (!isInit) {
        return
      }
      if (isLog.current) {
        console.log("updateCurrentUnit ext value ", value)
      }
      setCurrentUnit(value)
    },
    [setCurrentUnit],
  )

  useEffect(() => {
    latestCount.current = count
    setIsTriggerAddToCart(true)
  }, [count])

  useEffect(() => {
    if (isLog.current) {
      console.log("[effect] unit changed ", unit)
    }
    latestUnitRef.current = unit
  }, [unit])

  useEffect(() => {
    dispatch({
      type: ProductActionTypes.setUnits,
      payload: {
        parentKit,
        initialUnits,
        isOnlyContainers,
        unitMeasure,
      },
    })
  }, [parentKit, initialUnits, isOnlyContainers, unitMeasure])

  useEffect(() => {
    dispatch({
      type: ProductActionTypes.setCurrentSpecification,
      payload: {
        uuid: uuid || null,
        specification,
        parentKit,
        isLog: isLog.current,
      },
    })
  }, [specification, uuid, parentKit])

  useEffect(() => {
    dispatch({
      type: ProductActionTypes.setTotalQty,
      payload: {
        sample: !!currentSpecification?.isSampleRemoved
          ? 0
          : currentSpecification?.sample || 0,
        total: total_qty,
      },
    })
  }, [
    currentSpecification?.isSampleRemoved,
    currentSpecification?.sample,
    total_qty,
    uuid,
  ])

  useEffect(() => {
    initialHandle()
  }, [initialHandle, uuid])

  useEffect(() => {
    if (isLog.current) {
      console.log("[effect] count changed ", count)
    }
  }, [count])

  useEffect(() => {
    if (!isInit || !isTriggerAddToCart || isFetching) {
      return
    }
    if (isLog.current) {
      console.log(
        "!isInit || !isTriggerAddToCart || isFetching ",
        !isInit,
        " ",
        !isTriggerAddToCart,
        " ",
        isFetching,
      )
    }
    if (inCart && !isRemoved) {
      setIsTriggerAddToCart(false)
      addToCart()
    }
  }, [addToCart, inCart, isInit, isRemoved, isFetching, isTriggerAddToCart])

  useEffect(() => {
    if (uuid === null) {
      return
    }
    if (isAnimate) {
      setTimeout(() => {
        updateSpecification({
          uuid: uuid,
          isAnimate: false,
        })
        dispatch({
          type: ProductActionTypes.setIsAnimate,
          payload: false,
        })
      }, 3000)
    }
  }, [isAnimate, updateSpecification, uuid])

  useEffect(() => {
    if (isCountError) {
      setTimeout(() => {
        dispatch({
          type: ProductActionTypes.setIsCountError,
          payload: false,
        })
      }, 3000)
    }
  }, [isCountError])

  useEffect(() => {
    dispatch({
      type: ProductActionTypes.setStores,
      payload: {
        stores: initialStores,
        maxQty: max_qty,
        minQty: min_qty,
      },
    })
  }, [max_qty, min_qty, initialStores])

  return {
    isFavorites,
    availableStatus: availableStatus,
    path: path,
    units: units || [],
    updateCurrentCount: updateCurrentCount,
    updateCurrentUnit: updateCurrentUnit,
    totalQty: totalQty,
    priceCalculate: priceCalc,
    currentUnit: unit,
    currentCount: count,
    unitMeasure: unitMeasure,
    isFetching: isFetching,
    isFetchingFavorites: isFetchingFavorites,
    inCart: inCart,
    addToCart,
    removeFromCart,
    isRemoved,
    properties: properties,
    setInCart: setInCartHandle,
    setIsFetching: setIsFetching,
    isAnimate,
    storesAvailable: storesAvailable,
    stores: stores,
    storesQty: storesAvailable.length,
    isCopiedPath: isCopiedPath,
    copyPath: copyPath,
    share: share,
    isShowShare,
    setIsShowShare,
    sharePath,
    uuid: uuid,
    alias: alias,
    totalQtyBase: total_qty,
    priceUnit: price,
    child: child,
    kit: kit,
    categories: categories,
    description: description,
    fastQty: fast_qty,
    name: name,
    images: images,
    isKit: getIsKit(kit),
    kitParents: kit_parents,
    isBestseller: is_bestseller,
    isNew: is_new,
    code: removeZeroStart(code || ""),
    article: article,
    copyArticle: copyArticle,
    isCopiedArticle: isCopiedArticle,
    isAllowSample: allow_sample,
    counter: counter,
    isCopiedCode: isCopiedCode,
    copyCode: copyCode,
    isCountError,
    setIsCountError: setIsCountErrorHandle,
    isInit: isInit && uuid !== null,
    isAdded,
    setIsAdded,
    isAvailable: isAvailable,
    currentSpecification: currentSpecification,
    analogs: makedAnalogs,
    analogsIds: makedAnalogsIds,
    updateDiscount,
    isCompares,
    toggleCompares,
    params: params,
    isVariate,
    variations,
  }
}
